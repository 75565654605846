/* input 태그 자동완성해도 배경색 하얀색 유지 */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 500px white inset !important;
  }

  body, html{
    width:100%;
    height:100vh;
    margin: 0;
    padding: 0;
    background-color: #F3F3F6;
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard-Regular';
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #F3F3F6;
    margin: 0;
    padding: 0;
  }

  @keyframes SlideM {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(200px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes SlideD {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(450px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  body.draw-page-style {
    width:100%;
    height:115vh;
    margin: 0;
    padding: 0;
    background-color: #F3F3F6;
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard-Regular';

  }
